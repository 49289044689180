.work-menu .left-menu {
    min-width: 300px;
    max-width: 500px;
}
.work-menu .left-menu-search {
    min-width: 500px;
    max-width: 500px;
}
.search-page-input {
    width: 120px;
}
.search-page-indicator-input {
    width: 80px;
}
ul.folder-tree {
    padding-left: 1em;
}
.folder-tree, .folder-tree ul {
    list-style-type: none;
}
.folder-tree .caret {
    cursor: pointer;
    user-select: none;
}
.folder-tree .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
}
.folder-tree .caret.down::before {
    transform: rotate(90deg);
}

.team-media-item {
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: .5em;
    margin-left: .5em;
}
.team-media-item:hover {
    border-color: grey;
}
.team-media-item img {
    background-color: white;
    max-width: 200px;
    max-height: 200px;
}

.team-media-edit-modal-body {
    display: flex;
    align-content: center;
    justify-content: center;
}

.table-search-header:hover {
    background-color: #58b0b2;
}
.table-search-header.sorted {
    background-color: cadetblue;
}
.table-search-header .caret {
    cursor: pointer;
    user-select: none;
}
.table-search-header .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
    transform: rotate(270deg);
}
.table-search-header .caret.down::before {
    transform: rotate(90deg);
}
.table-search-cell.edited {
    border-left: 1px solid red;
}

.btn.btn-link {
    text-decoration: none;
    padding : 0;
}
.btn.btn-link:hover {
    text-decoration: underline;
}

.col-form-label {
    min-width: 200px;
}

.alert-fixed {
    position: fixed;
    bottom: -16px;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.team-update-proposals {
    font-size: small;
    font-family: tahoma,arial,helvetica,sans-serif;
    margin-bottom: 1em;
}
.team-update-proposals > div {
    padding: .5em;
    background-color: yellow;
}
.team-update-proposals button {
    font-size: small;
}

.btn.btn-link.small {
    font-size: small;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.app-header {
    height: 70px;
}
.left-menu {
    max-height: calc(100vh - 70px);
    overflow-y: auto;
}
.work-menu .right-menu {
    max-height: calc(100vh - 70px - 42px - 42px);
    overflow-y: auto;
}

.search-separator {
    margin-top: 5px;
    margin-bottom: 5px;
}
